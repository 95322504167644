import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"

export const VolunteerFormPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section" style={{padding: "0px", paddingBottom: "20px", marginLeft: "-15px"}}>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <div align="center"><Helmet>
                <script src={withPrefix('volunteer-jotform.js')} type="text/javascript" />
              </Helmet><iframe id="JotFormIFrame-201030322922134" class="ef-volunteer-form-iframe" title="Volunteer Form" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://form.jotform.com/201030322922134" frameborder="0" scrolling="no"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

VolunteerFormPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const VolunteerFormPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.pageMetadata}>
      <VolunteerFormPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

VolunteerFormPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default VolunteerFormPage

export const volunteerFormPageQuery = graphql`
  query VolunteerFormPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        title
      }
    }
  }
`
